<template>
  <div>
    <Menu />
    <div id="ContactUs">
      <!--  -->
      <div class="ContactUs">
        <img src="../assets/images/contactUs_banner.png" class="banner w100" />
        <div class="content bg_fff cuContent">
          <!-- crumbs -->
          <div class="crumbs">
            <div class="content d-flex d-flex-middle">
              <p class="color9 fontSize12">当前所在位置：</p>
              <router-link to="/" class="color9 fontSize12">首页</router-link>
              <p class="color9 fontSize12 and">></p>
              <p class="color fontSize12">在线留言</p>
            </div>
          </div>
          <!--  -->
          <div class="form">
            <p class="formTitle">在线留言</p>
            <div class="d-flex d-flex-middle">
              <input type="text" v-model="company" id="" value="" placeholder="公司名称" />
            </div>
            <div class="d-flex d-flex-middle">
              <input type="tel" v-model="Phone" id="" value="" placeholder="电话" />
            </div>
            <div class="d-flex d-flex-middle">
              <input type="text" v-model="realName" id="" value="" placeholder="姓名" />
            </div>
            <div class="d-flex d-flex-middle">
              <input type="email" v-model="email" id="" value="" placeholder="邮箱" />
            </div>
            <div class="d-flex d-flex-middle">
              <textarea v-model="content" placeholder="留言内容"></textarea>
            </div>
            <p class="submit" @click="submit()">提交</p>
          </div>
        </div>
        <!-- map -->
        <div class="map">
          <div id="map"></div>
          <div class="mapContent">
            <div class="mTitle d-flex d-flex-bottom">
              <p class="fontSize24 color3 b">联系我们</p>
              <p class="fontSize18 color9">CONTACT US</p>
            </div>
            <div class="m_list d-flex d-flex-middle d-flex-between d-flex-wrap">
              <div class="m_li d-flex d-flex-top">
                <div class="d-flex d-flex-middle">
                  <img src="../assets/images/cu_icon_1.png" />
                  <p class="color9 fontSize18">客服热线：</p>
                </div>
                <p class="color0 fontSize18 m_li_txt">027-87688608/18120409742</p>
              </div>
              <div class="m_li d-flex d-flex-top">
                <div class="d-flex d-flex-middle">
                  <img src="../assets/images/cu_icon_2.png" />
                  <p class="color9 fontSize18">企业邮箱：</p>
                </div>
                <p class="color0 fontSize18 m_li_txt">100000@qq.com</p>
              </div>
              <div class="m_li d-flex d-flex-top">
                <div class="d-flex d-flex-middle">
                  <img src="../assets/images/cu_icon_3.png" />
                  <p class="color9 fontSize18">官方网址：</p>
                </div>
                <p class="color0 fontSize18 m_li_txt">www.baidu.com</p>
              </div>
              <div class="m_li d-flex d-flex-top">
                <div class="d-flex d-flex-middle">
                  <img src="../assets/images/cu_icon_4.png" />
                  <p class="color9 fontSize18">客服热线：</p>
                </div>
                <p class="color0 fontSize18 m_li_txt">湖北省武汉市江汉区汉口火车站银墩路奥里商务大厦三楼</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot />
    <RightGuide />

  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import { submitMessage, agreement } from "@/api/app"
export default {
  name: "Home",
  components: {
    Menu, Foot, RightGuide, BaiduMap,
  },
  data() {
    return {
      addRessUrls: require('../assets/images/address.png'),
      company: '',
      Phone: '',
      realName: '',
      email: '',
      content: '',
      agreementData: {}
    };
  },
  mounted() {
    this.baiduMap();
    this.getAgreement()
  },

  methods: {

    //联系我们信息
    getAgreement() {
      const params = {
        key:"关于我们"
      }
      agreement(params).then((res) => {
        if (res.status) {
   
        }
      })
    },

    // 留言
    submit() {
      var that = this
      // 
      
      if (this.Phone.length != 11) {
        this.$message.error('手机号码不正确')
        return
      }
      if (this.realName == '') {
        this.$message.error('请填写您的姓名')
        return
      }
      if (this.content == '') {
        this.$message.error('请填写您的留言')
        return
      }

      const params = {
        message_Company: this.company,
        message_Phone: this.Phone,
        message_RealName: this.realName,
        message_Email: this.email,
        message_Content: this.content,
      }
      submitMessage(params).then((res) => {
        if (res.status) {
          this.$message.success('提交成功，等待客服人员与您联系')
        }
        else {
          this.$message.warning(res.message)
        }
      })
    },


    baiduMap() {
      var _this = this
      var map = new BMap.Map("map"); //创建地图实例

      var point = new BMap.Point(114.260537, 30.623601 - 0.002); // 创建点坐标
      map.centerAndZoom(point, 17); // 初始化地图，设置中心点坐标和地图级别
      // map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

      map.addControl(new BMap.NavigationControl()); //添加导航控件
      map.addControl(new BMap.ScaleControl()); // 添加比例尺控件
      map.addControl(new BMap.OverviewMapControl()); //添加地图缩略图
      map.addControl(new BMap.MapTypeControl()); //添加地图类型
      //map.setMapStyle({ style: 'midnight' }) //地图风格

      //var marker = new BMap.Marker(point); // 创建标注

      // 创建小车图标
      var myIcon = new BMap.Icon(_this.addRessUrls, new BMap.Size(150, 132), {
        anchor: new BMap.Size(150, 66)
      });
      // 创建Marker标注，使用小车图标
      var marker = new BMap.Marker(point, {
        icon: myIcon
      });

      map.addOverlay(marker); // 将标注添加到地图中



      var opts = {
        width: 200,     // 信息窗口宽度
        height: 100,     // 信息窗口高度
        title: "湖北安保", // 信息窗口标题
      }
      //提示信息
      var infoWindow = new BMap.InfoWindow("地址：湖北省武汉市江汉区汉口火车站银墩路奥里商务大厦三楼", opts);

      // 鼠标移上标注点要发生的事
      marker.addEventListener("mouseover", function () {
        this.openInfoWindow(infoWindow);
      });

      // 鼠标移开标注点要发生的事
      marker.addEventListener("mouseout", function () {
        this.closeInfoWindow(infoWindow);
      });
    },
  },
};
</script>

<style lang="scss">
/*  */
body {
  background-color: #fafafa;
}

#ContactUs {

/*  */
.ContactUs {
  position: relative;
  box-sizing: border-box;
  padding-top: 270px;
  min-height: 800px;
}
.banner {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.cuContent {
  position: relative;
  z-index: 22;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 40px 50px 40px;
}
/*  */
.form {
  width: 900px;
  height: 900px;
  background: #f5f5f5;
  border-radius: 10px;
  margin: 30px auto;
  box-sizing: border-box;
  padding: 0 90px;
}
.formTitle {
  line-height: 136px;
  text-align: center;
  font-size: 30px;
  color: #333;
}
.form div {
  width: 100%;
  min-height: 60px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 25px;
}
.form div input {
  flex: 1;
  line-height: 20px;
  height: 20px;
  font-size: 16px;
}
.form div textarea {
  border: none;
  flex: 1;
  height: 200px;
  resize: none;
  outline: none;
}
.form .submit {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: #0087ed;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  margin-top: 50px;
}
/*  */
.map {
  height: 720px;
  position: relative;
}
#map {
  width: 100%;
  height: 740px;
}
.mapContent {
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -600px;
  z-index: 222;
  background-color: #fff;
  width: 1200px;
  border-radius: 10px;
  box-sizing: border-box;
  padding-left: 20px;
  box-sizing: border-box;
  padding: 30px;
}
.mTitle {
  margin-bottom: 30px;
}
.mTitle p {
  margin-right: 20px;
}
.m_list {
}
.m_li {
  width: 50%;
  line-height: 24px;
  margin: 10px 0;
}
.m_li div {
  width: 130px;
}
.m_li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
}
</style>